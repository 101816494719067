import { type FC } from "react"

import { cn } from "@/utils/cn"

import { type PageContentProps } from "./types"

const PageContent: FC<PageContentProps> = ({ children, className }) => (
  <div className={cn("mx-auto max-w-[800px]", className)}>{children}</div>
)

export default PageContent
